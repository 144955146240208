window.onload = async () => {
  // The Auth0 client, initialized in configureClient()
  let auth0Client = null;

  /**
   * Starts the authentication flow
   */
  let login = async (signup) => {
    // window.location.assign("https://secure.mpilo.ai");
    try {
      let options = {
        authorizationParams: {
          redirect_uri: window.location.origin,
        },
      };

      if (signup) {
        options.authorizationParams.screen_hint = "signup";
        localStorage.setItem("isSignup", true);
      }

      await auth0Client.loginWithRedirect(options);
    } catch (err) {
      console.log("Log in failed", err);
    }
  };

  let signup = async () => {
    await login(true);
  };

  /**
   * Initializes the Auth0 client
   */
  let configureClient = async () => {
    console.log("Configuring Auth0 client");

    auth0Client = await auth0.createAuth0Client({
      domain: "mpilo.us.auth0.com",
      clientId: "zlTEXkjAGwA8msnn0OsepfbYgq12zhjH",
      cookieDomain: ".mpilo.ai",
      cacheLocation: "localstorage",
    });

    return auth0Client;
  };

  await configureClient();

  window.signup = signup;
  window.login = login;

  window.isAuthenticated = await auth0Client.isAuthenticated();
  console.log("isAuthenticated", window.isAuthenticated);

  const elements = {
    authButtons: document.getElementById("authButtons"),
    dashboardButton: document.getElementById("dashboardButton"),
    mobileAuthButtons: document.getElementById("mobileAuthButtons"),
    mobileDashboardButton: document.getElementById("mobileDashboardButton"),
  };

  const classes = window.isAuthenticated
    ? {
        authButtons: ["lg:hidden", "lg:flex"],
        dashboardButton: ["lg:flex", "lg:hidden"],
        mobileAuthButtons: ["hidden", "grid"],
        mobileDashboardButton: ["grid", "hidden"],
      }
    : {
        authButtons: ["lg:flex", "lg:hidden"],
        dashboardButton: ["lg:hidden", "lg:flex"],
        mobileAuthButtons: ["grid", "hidden"],
        mobileDashboardButton: ["hidden", "grid"],
      };

  Object.keys(elements).forEach((key) => {
    elements[key].classList.remove(classes[key][1]);
    elements[key].classList.add(classes[key][0]);
  });

  const query = window.location.search;
  const shouldParseResult = query.includes("code=") && query.includes("state=");

  if (shouldParseResult) {
    try {
      const result = await auth0Client.handleRedirectCallback();
      const isSignup = localStorage.getItem("isSignup");
      if (isSignup) {
        localStorage.removeItem("isSignup");
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "registrationComplete",
        });
      }

      window.location.assign("https://secure.mpilo.ai");
    } catch (err) {
      console.log("Error parsing redirect:", err);
    }

    window.history.replaceState({}, document.title, "/");
  }
};
